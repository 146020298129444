import React from "react"
import styled, {
  ThemeProvider,
  createGlobalStyle,
  css,
} from "styled-components/macro"

import { useDarkMode } from "@hooks/useDarkMode"
import { lightTheme, darkTheme } from "@config/theme"
import { normalize } from "styled-normalize"
import NavBar from "@components/organisms/NavBar"
import SEO from "@components/molecules/SEO"

const bodyStyles = css`
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  width: 100vw;
  background-color: #f9f9f9;
  color: ${({ theme }) => theme.colors.text};
  transition: all 0.5s linear;
`

const GlobalStyle = createGlobalStyle`
 ${normalize}

 @font-face {
    font-family: 'Source Code Pro';
    src: url('/fonts/SourceCodePro-Regular.eot');
    src: url('/fonts/SourceCodePro-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/SourceCodePro-Regular.woff2') format('woff2'),
        url('/fonts/SourceCodePro-Regular.woff') format('woff'),
        url('/fonts/SourceCodePro-Regular.ttf') format('truetype'),
        url('/fonts/SourceCodePro-Regular.svg#SourceCodePro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

 html, body, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
    width:100%;
    overflow-x:hidden;
  }

 html {
   font-size: 100%
 }

 body {
  ${bodyStyles}
 }

 * {
    box-sizing: border-box;
  }
  #gatsby-focus-wrapper {
    display: flex;
    @media (max-width: 768px) {
        flex-direction: column;
    }
  }

 hr {
    border-color: rgba(0,0,0,0.08);
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,0.1);
    width: 90%;
 }
 a {text-decoration: none; color: ${({ theme }) => theme.colors.text};}
 h3 {font-weight: 400}
`
const Main = styled.main`
  flex: 1;
  overflow-y: auto;
`
export default function Global({ children }) {
  const [theme] = useDarkMode()
  const themeMode = theme === "light" ? lightTheme : darkTheme

  return (
    <ThemeProvider theme={themeMode}>
      <SEO />
      <GlobalStyle />
      <NavBar />
      <Main>{children}</Main>
    </ThemeProvider>
  )
}
