import React from "react"
import Link from "@components/atoms/Link"
import Icon from "@components/atoms/Icon"

export default function IconLink({ url, name }): JSX.Element {
  return (
    <>
      <Link to={url} target="_blank" rel="noopener noreferrer">
        <Icon name={name} size={30} />
      </Link>
    </>
  )
}
