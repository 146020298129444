import { useEffect } from "react"
import { useLocalStorage } from "./"

export const useDarkMode = () => {
  const [colorMode, setColorMode] = useLocalStorage("color-mode")

  useEffect(() => {
    const prefersDark = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches
    const initialMode = prefersDark ? "dark" : "light"
    !colorMode && setColorMode(initialMode)
  }, [])

  return [colorMode, setColorMode]
}
