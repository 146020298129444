import React from "react"
import styled from "styled-components"

type Props = {
  to: string
  children: React.ReactNode
  rel?: string
  target?: string
}

const StyledLink = styled.a`
  color: inherit;
  & :visited {
    color: inherit;
  }
`

export default function Link({
  to,
  children,
  rel,
  target,
}: Props): JSX.Element {
  return (
    <StyledLink href={to} rel={rel} target={target}>
      {children}
    </StyledLink>
  )
}

Link.defaultProps = {
  rel: "noopener noreferrer",
  target: "_blank",
}
