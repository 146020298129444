import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

export default function SEO({ seo = {} }) {
  const { strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        siteName
        favicon {
          localFile {
            url
          }
        }
        defaultSeo {
          metaTitle
          metaDescription
          shareImage {
            localFile {
              url
            }
          }
        }
      }
    }
  `)

  const { siteName, defaultSeo, favicon } = strapiGlobal

  const fullSeo = { ...defaultSeo, ...seo }

  fullSeo.metaTitle = `${fullSeo.metaTitle} | ${siteName}`

  const getMetaTags = () => {
    const tags = []

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: "og:title",
          content: fullSeo.metaTitle,
        },
        {
          name: "twitter:title",
          content: fullSeo.metaTitle,
        }
      )
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: "description",
          content: fullSeo.metaDescription,
        },
        {
          property: "og:description",
          content: fullSeo.metaDescription,
        },
        {
          name: "twitter:description",
          content: fullSeo.metaDescription,
        }
      )
    }
    if (fullSeo.shareImage) {
      const imageUrl = fullSeo.shareImage.localFile.url
      tags.push(
        {
          name: "image",
          content: imageUrl,
        },
        {
          property: "og:image",
          content: imageUrl,
        },
        {
          name: "twitter:image",
          content: imageUrl,
        }
      )
    }
    if (fullSeo.article) {
      tags.push({
        property: "og:type",
        content: "article",
      })
    }
    tags.push({ name: "twitter:card", content: "summary_large_image" })

    return tags
  }

  const metaTags = getMetaTags()

  return (
    <Helmet
      title={fullSeo.metaTitle}
      link={[
        {
          rel: "icon",
          href: favicon.localFile.url,
        },
      ]}
      meta={metaTags}
    />
  )
}

// export default function SEO({ title, desc, image, pathname, article }) {
//   const { strapiWebsiteGlobal: siteGlobal } = useStaticQuery(graphql`
//     {
//       strapiWebsiteGlobal {
//         siteUrl
//         shortName
//         titleTemplate
//         twitter
//         author
//         siteLanguage
//         created: created_at(formatString: "YYYY-MM-DD")
//         updated: updated_at(formatString: "YYYY-MM-DD")

//         defaultSeo {
//           metaDescription
//           metaTitle
//           shareImage {
//             localFile {
//               publicURL
//             }
//           }
//         }
//       }
//     }
//   `)
//   const {
//     siteUrl,
//     shortName,
//     titleTemplate,
//     twitter,
//     author,
//     siteLanguage,
//     created,
//     updated,
//     defaultSeo,
//   } = siteGlobal
//   const {
//     metaTitle: defaultTitle,
//     metaDescription: defaultDescription,
//   } = defaultSeo
//   const defaultImage = defaultSeo.shareImage.localFile.publicURL

//   const seo = {
//     title: title || defaultTitle,
//     description: desc || defaultDescription,
//     image: `${siteUrl}${image || defaultImage}`,
//     url: `${siteUrl}${pathname || "/"}`,
//   }

//   let schemaOrgJSONLD = [
//     {
//       "@context": "http://schema.org",
//       "@type": "WebSite",
//       "@id": siteUrl,
//       url: siteUrl,
//       name: defaultTitle,
//     },
//   ]
//   if (article) {
//     schemaOrgJSONLD = [
//       {
//         "@context": "http://schema.org",
//         "@type": "BlogPosting",
//         "@id": seo.url,
//         url: seo.url,
//         name: title,
//         headline: seo.title,
//         image: {
//           "@type": "ImageObject",
//           url: seo.image,
//         },
//         description: seo.description,
//         datePublished: created,
//         dateModified: updated,
//         author: {
//           "@type": "Person",
//           name: author,
//         },
//         publisher: {
//           "@type": "Organization",
//           name: author,
//           logo: {
//             "@type": "ImageObject",
//             url: siteUrl + defaultImage,
//           },
//         },
//         isPartOf: siteUrl,
//         mainEntityOfPage: {
//           "@type": "WebSite",
//           "@id": siteUrl,
//         },
//       },
//     ]
//   }
//   return (
//     <Helmet defaultTitle={defaultTitle} titleTemplate={titleTemplate}>
//       <html lang={siteLanguage} />
//       {title && <title>{title}</title>}
//       <meta name="description" content={seo.description} />
//       <meta name="image" content={seo.image} />
//       <meta name="apple-mobile-web-app-title" content={shortName} />
//       <meta name="application-name" content={shortName} />
//       <script type="application/ld+json">
//         {JSON.stringify(schemaOrgJSONLD)}
//       </script>

//       {/* OpenGraph  */}
//       <meta property="og:url" content={seo.url} />
//       <meta property="og:type" content={article ? "article" : "website"} />
//       <meta property="og:title" content={seo.title} />
//       <meta property="og:description" content={seo.description} />
//       <meta property="og:image" content={seo.image} />

//       {/* Twitter Card */}
//       <meta name="twitter:card" content="summary_large_image" />
//       <meta name="twitter:creator" content={twitter} />
//       <meta name="twitter:title" content={seo.title} />
//       <meta name="twitter:description" content={seo.description} />
//       <meta name="twitter:image" content={seo.image} />
//     </Helmet>
//   )
// }
