const colors = {
  teal01: "#008080",
  teal02: "#007373",
  teal03: "#006666",
  teal04: "#005a5a",
  teal05: "#004d4d",
  teal06: "#004040",
  teal07: "#003333",
  teal08: "#002626",
  teal09: "#001a1a",
  teal10: "#000d0d",
}

export const lightTheme = {
  colors: {
    primary: colors.teal05,
    text: colors.teal09,
    ...colors,
  },
}
export const darkTheme = {
  colors: {
    primary: colors.teal09,
    text: colors.teal06,
    ...colors,
  },
}
