import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components/macro"

import LgLogo from "@assets/lgrayland-logo.svg"
import Burger from "@components/molecules/Burger"
import Nav from "@components/atoms/Nav"
import IconLink from "@components/molecules/IconLink"
import DarkToggle from "@components/molecules/DarkToggle"
import useDeviceDetect from "@hooks/useDeviceDetect"

const SideBar = styled.aside`
  /* position: relative; */
  width: 300px;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 1.5em 1em;
  color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media print {
    display: none;
  }
`

const LogoLink = styled(Link)`
  text-align: center;
`

const Logo = styled(LgLogo)`
  color: ${({ theme }) => theme.colors.text};
  width: 70%;
  transition: all 0.5s linear;
  flex-shrink: 0;
`
const Header = styled.header`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f9f9f9;
  ${LogoLink} {
    height: 100%;
  }
  ${Logo} {
    width: auto;
    height: 100%;
  }
  @media print {
    display: none;
  }
`

const Bio = styled.p`
  text-align: center;
`

export default function NavBar() {
  const { isMobile } = useDeviceDetect()

  const { strapiProfile } = useStaticQuery(graphql`
    query {
      strapiProfile {
        bioExcerpt
        name
        socialMedia {
          ref
          name
          url
        }
      }
    }
  `)

  return (
    <>
      {isMobile ? (
        <Header>
          <Burger />
          <LogoLink to="/">
            <Logo />
          </LogoLink>
        </Header>
      ) : (
        <SideBar>
          <h1>{strapiProfile.name}</h1>
          <LogoLink to="/">
            <Logo />
          </LogoLink>

          <Bio>{strapiProfile.bioExcerpt}</Bio>
          <div>
            {strapiProfile.socialMedia.map(
              (link: { url: string; ref: string }) => (
                <IconLink url={link.url} name={link.ref} key={link.ref} />
              )
            )}
          </div>
          <hr />
          <Nav />
          <hr />
          <DarkToggle />
        </SideBar>
      )}
    </>
  )
}
