import React from "react"
import styled from "styled-components/macro"
import {
  Ruby,
  Javascript,
  Html5,
  Linkedin,
  ReactLogo,
  Adobephotoshop,
  Adobeillustrator,
  Css3,
  Rubyonrails,
  Nextdotjs,
} from "@styled-icons/simple-icons"
import { Vuejs } from "@styled-icons/fa-brands"
import { LinkedinWithCircle } from "@styled-icons/entypo-social"
import { Mail } from "@styled-icons/foundation"
import { Home } from "@styled-icons/ionicons-solid"

const Icons = {
  ruby: styled(Ruby)`
    color: #cc342d;
  `,
  rubyOnRails: styled(Rubyonrails)`
    color: #cc342d;
  `,
  vanillaJavascript: styled(Javascript)`
    color: #f1de4f;
  `,
  html5: styled(Html5)`
    color: #e44d26;
  `,
  css3: styled(Css3)`
    color: #2565ae;
  `,
  react: styled(ReactLogo)`
    color: #61dbfb;
  `,
  nextJs: styled(Nextdotjs)`
    color: black;
  `,
  vue: styled(Vuejs)`
    color: #41b883;
  `,
  photoshop: styled(Adobephotoshop)`
    color: #001e36;
    background-color: #31a8ff;
    border-radius: 5px;
  `,
  illustrator: styled(Adobeillustrator)`
    color: #300;
    background-color: #ff9a00;
    border-radius: 5px;
  `,
  linkedIn: Linkedin,
  linkedInRound: LinkedinWithCircle,
  mail: Mail,
  home: Home,
}

export default function Icon({ name, size = 40 }) {
  const renderIcon = () => {
    const IconName = Icons[name]
    return IconName ? <IconName size={size} /> : null
  }
  return <>{renderIcon()}</>
}
