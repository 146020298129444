import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components/macro"

interface StyledProps {
  open: boolean
}

const StyledNav = styled.nav`
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  align-items: center;
  z-index: 1;
  font-weight: bold;

  @media (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.primary};
    position: fixed;
    transform: ${({ open }: StyledProps) =>
      open ? "translateX(0)" : "translateX(-100%)"};
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
  }
`

const NavLink = styled(Link)`
  text-decoration: none;
  padding: 0.5em;
  & :hover {
    color: ${({ theme }) => theme.colors.text};
  }
  & :visited {
    color: inherit;
  }
`

interface NavProps {
  open?: boolean
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Nav({ open = false, setOpen }: NavProps) {
  const { strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        routes {
          links {
            label
            path
          }
        }
      }
    }
  `)
  const { routes } = strapiGlobal
  return (
    <>
      <StyledNav open={open}>
        {routes.links.map((route: { label: string; path: string }) => (
          <NavLink
            key={route.label}
            to={route.path}
            onClick={() => (setOpen ? setOpen(false) : null)}
          >
            {route.label}
          </NavLink>
        ))}
      </StyledNav>
    </>
  )
}
